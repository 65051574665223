<template>
  <div class="subselections-container">
    <h6
      class="subselection-of-product"
      v-for="(subselectionCategoryWithOptions, index) in subselectionCategoriesWithOptions"
      :key="index"
    >
      <span
        class="subselection-title"
      >{{subselectionCategoryWithOptions.subselectionCategory.subselectionCategoryNames.translation[language].name}}:</span>
      <span
        v-for="(subselectionOption, indexOfSubselection) in subselectionCategoryWithOptions.subselectionOptions"
        :key="indexOfSubselection"
      >{{ (index+1)==subselectionCategoryWithOptions.subselectionOptions.length ? subselectionOption.names.translation[language].name : (subselectionOption.names.translation[language].name +', ')}}</span>
    </h6>
  </div>
</template>
<script>
export default {
  name: "SubselectionsOfProductAtOrder",
  created() {
  },
  props: ["productSubselections", "language"],
  data() {
    return {
    };
  },
  computed: {
    subselectionCategoriesWithOptions: function () {
      let subselectionCategoryIds = [...new Set(this.productSubselections.map(productSubselection => productSubselection.subselectionCategoryId))];
      let subselectionCategoriesWithOptions = [];
      subselectionCategoryIds.forEach(categoryId => {
        let productSubselectionCategoryAndOptions = this.productSubselections.find(productSubselection => productSubselection.subselectionCategoryId == categoryId);
        let subselectionCategory = {
          subselectionCategoryId: productSubselectionCategoryAndOptions.subselectionCategoryId,
          subselectionCategoryNames: productSubselectionCategoryAndOptions.subselectionCategoryNames
        }
        let subselectionOptionsOfThisCategory = [];
        this.productSubselections.forEach(productSubselection => {
          if (productSubselection.subselectionCategoryId == categoryId) {
            subselectionOptionsOfThisCategory.push(productSubselection.productSubselectionOption)
          }
        })
        let subselectionCategoryWithOptions = {
          subselectionCategory: subselectionCategory,
          subselectionOptions: subselectionOptionsOfThisCategory
        }
        subselectionCategoriesWithOptions.push(subselectionCategoryWithOptions);
      })
      return subselectionCategoriesWithOptions;
    },
  },
  methods: {
  },
  mounted() {
  }
};
</script>
<style >
.subselection-of-product {
  font-size: 14px;
  margin-bottom: 0px;
  color: #393939;
}

.subselection-title {
  font-weight: 700;
  margin-right: 6px;
}

.subselections-container {
  padding-left: 19px;
}
</style>